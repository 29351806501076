<template>
  <div style="width: 100%; height: 100%">
    <div style="width: 100%">
      <span
        style="
          font-size: 14px;
          font-weight: 400;
          color: #082254;
          opacity: 0.5;
          margin-right: 13px;
          cursor:pointer;
        "
        @click="toSearch"
        >告警查询/</span
      >
      <span style="font-size: 14px; font-weight: 400; color: #082254"
        >告警详情</span
      >
    </div>
    <div
      style="
        width: 100%;
        height: 135px;
        background: white;
        margin-top: 10px;
        padding-top: 30px;
        position: relative;
      "
    >
      <div
        style="
          position: absolute;
          width: 64px;
          height: 32px;
          border-radius: 2px;
          border: 1px solid #e52323;
          font-size: 14px;
          font-weight: 400;
          color: #e52323;
          top: 20px;
          left: 20px;
          line-height: 32px;
          text-align: center;
        "
      >
        {{detailData.alarmLevelName}}
      </div>
      <div
        style="
          position: absolute;
          font-size: 16px;
          font-weight: 600;
          color: #082254;
          top: 23px;
          left: 120px;
        "
      >
        {{detailData.faultDesc}}
      </div>
      <!-- <div
        style="
          position: absolute;
          width: 192px;
          height: 40px;
          border-radius: 2px;
          border: 1px solid #3f6df8;
          top: 75px;
          left: 20px;
          padding-left: 10px;
          padding-right: 10px;
        "
      >
        <div
          style="
            float: left;
            font-size: 14px;
            font-weight: 400;
            color: #3f6df8;
            height: 40px;
            line-height: 40px;
          "
        >
          冷冻水1#主机功率图
        </div>
        <div
          style="
            float: right;
            font-size: 14px;
            font-weight: 400;
            color: #3f6df8;
            height: 40px;
            line-height: 40px;
          "
        >
          <i class="el-icon-d-arrow-right"></i>
        </div>
      </div> -->
      <div style="width: 485px; margin: 0 auto">
        <el-steps :active="active" finish-status="success">
          <el-step title="未处理"></el-step>
          <el-step title="处理中"></el-step>
          <el-step :title="finishTitle"></el-step>
        </el-steps>
      </div>

      <!-- <div
        style="
          width: 485px;
          margin: 0 auto;
          margin-top: 10px;
          font-size: 14px;
          font-weight: 400;
          color: #082254;
        "
      >
        维保人员：王源
      </div> -->
    </div>
    <div
      style="
        width: 100%;
        height: 224px;
        background: white;
        margin-top: 20px;
        padding: 20px;
      "
    >
      <div
        style="width: 100%; font-weight: 600; color: #082254; font-size: 16px"
      >
        告警详情
      </div>
      <div
        style="
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 15px;
        "
      >
        <div
          style="
            width: 33.3%;
            font-size: 14px;
            font-weight: 400;
            color: #082254;
          "
        >
          告警编号：{{detailData.id}}
        </div>
        <div
          style="
            width: 33.3%;
            font-size: 14px;
            font-weight: 400;
            color: #082254;
          "
        >
          设备/系统：{{detailData.instanceAndAttrName}}
        </div>
        <div
          style="
            width: 33.3%;
            font-size: 14px;
            font-weight: 400;
            color: #082254;
          "
        >

          催单次数：{{detailData.reminders?detailData.reminders:'--'}}次
        </div>
      </div>

      <div
        style="
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 15px;
        "
      >
        <div
          style="
            width: 33.3%;
            font-size: 14px;
            font-weight: 400;
            color: #082254;
          "
        >
          告警类型：{{detailData.alarmType | formatAType}}
        </div>
        <div
          style="
            width: 33.3%;
            font-size: 14px;
            font-weight: 400;
            color: #082254;
          "
        >
          故障描述：{{detailData.faultDesc}}
        </div>
        <div
          style="
            width: 33.3%;
            font-size: 14px;
            font-weight: 400;
            color: #082254;
          "
        >
          告警时间：{{detailData.alarmStartTime | formateV}}
        </div>
      </div>

      <div
        style="
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 15px;
        "
      >
        <div
          style="
            width: 33.3%;
            font-size: 14px;
            font-weight: 400;
            color: #082254;
          "
        >
          持续故障：{{detailData.continued?'是':'否'}}
        </div>
        <div
          style="
            width: 33.3%;
            font-size: 14px;
            font-weight: 400;
            color: #082254;
          "
        >
          故障影响：{{detailData.faultEffect?"detailData.faultEffect":"--"}}
        </div>
        <div
          style="
            width: 33.3%;
            font-size: 14px;
            font-weight: 400;
            color: #082254;
          "
        >
          持续时间：{{detailData.continueTime}}小时
        </div>
      </div>

      <div
        style="
          width: 200px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0 auto;
          margin-top: 20px;
        "
      >
        <div
          style="
            width: 70px;
            height: 32px;
            background: #3f6df8;
            border-radius: 2px;
            text-align: center;
            line-height: 32px;
            color: white;
            font-size: 14px;
            font-weight: 400;
          "
        >
          下发
        </div>
        <div
          style="
            width: 70px;
            height: 32px;
            border-radius: 2px;
            border: 1px solid #3f6df8;
            line-height: 32px;
            text-align: center;
            color: #3f6df8;
            font-size: 14px;
            font-weight: 400;
          "
        >
          忽略
        </div>
      </div>
    </div>
    <div
      style="
        width: 100%;
        height: 400px;
        background: white;
        margin-top: 20px;
        padding: 20px;
      "
    >
      <div
        style="width: 100%; font-weight: 600; color: #082254; font-size: 16px"
      >
        该设备告警历史记录(10)
      </div>

      <div style="width: 100%; margin-top: 20px">
        <el-table
          :data="tableData"
          style="width: 100%; overflow: auto; margin-top: 30px"
          :row-class-name="tableRowClassName"
          v-loading="loading"
        >
          <!--@selection-change="handleSelectionChange" <el-table-column type="selection" width="55"> </el-table-column> -->
          <el-table-column type="index" width="50" label="序号">
          </el-table-column>
          <el-table-column label="优先级" width="120">
            <template slot-scope="scope">{{ scope.row.date }}</template>
          </el-table-column>
          <el-table-column
            prop="address"
            label="告警类型"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="address"
            label="告警时间"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="address"
            label="维保完成时间"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="address"
            label="故障描述"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="address"
            label="持续故障"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="address"
            label="故障影响"
            show-overflow-tooltip
          >
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
// import * as echarts from "echarts";
import {
  getAlarmDetail
} from "@/request/apiLg";
export default {
  filters: {
    formateV: function (value) {
      var date1 = new Date(value);
      var YY = date1.getFullYear() + "-";
      var MM =
        (date1.getMonth() + 1 < 10
          ? "0" + (date1.getMonth() + 1)
          : date1.getMonth() + 1) + "-";
      var DD = date1.getDate() < 10 ? "0" + date1.getDate() : date1.getDate();
      var hh =
        (date1.getHours() < 10 ? "0" + date1.getHours() : date1.getHours()) +
        ":";
      var mm =
        (date1.getMinutes() < 10
          ? "0" + date1.getMinutes()
          : date1.getMinutes()) + ":";
      var ss =
        date1.getSeconds() < 10 ? "0" + date1.getSeconds() : date1.getSeconds();
      return YY + MM + DD + " " + hh + mm + ss;
    },
    formatAType(value){
      if(value === 'OVERRUN'){
        return "数据异常"
      }
      else if(value === 'TIMEOUT'){
        return "通信异常"
      }
      else if(value === 'DIAGNOSIS'){
        return "故障诊断"
      }
    }
  },
  data() {
    return {
      finishTitle:'',
      loading: false,
      active: 2,
      multipleSelection: [],
      tableData: [],
      value1: "",
      isActivenTime: 1,
      detailData:{},
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",
    };
  },
  methods: {
    toSearch(){
      this.$router.push({ name: "alarmSearch" });
    },
    next() {
      if (this.active++ > 2) this.active = 0;
    },
    clickNH(index) {
      this.isActivenTime = index;
    },
    tableRowClassName({ rowIndex }) {
      if ((rowIndex + 1) % 2 == 0) {
        return "col-class1";
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
  mounted(){
    getAlarmDetail(this.$route.query.id).then(res=>{
      console.log("res",res)
      this.detailData = res.data.data
      if(this.detailData.alarmStatus === "UNTREATED"){
        this.active = 0
        this.finishTitle = '已处理'
      }
      else if(this.detailData.alarmStatus === "PROCESSED"){
        this.active = 3
        this.finishTitle = '已处理'
      }
      else if(this.detailData.alarmStatus === "INGORED"){
        this.active = 3
        this.finishTitle = '已忽略'
      }
    })
  }
};
</script>
<style>
.el-step__head.is-success {
  color: #1a7aff;
  border-color: #1a7aff;
}
.el-step__title.is-success {
  color: #1a7aff;
}
</style>
<style scoped>
.el-input__inner {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  height: 32px !important;
}
.el-date-editor >>> .el-range-separator {
  width: 8% !important;
}
.el-date-editor >>> input {
  height: 30px;
}
.el-select >>> input {
  width: 140px;
  height: 32px;
}
.el-select >>> .el-input__icon {
  line-height: 32px;
}
.title1 {
  padding-left: 30px;
  padding-right: 30px;
  width: 24%;
  height: 100%;
  background: linear-gradient(135deg, #f6b084 0%, #ea9b7a 46%, #e97f75 100%);
  border-radius: 4px;
  position: relative;
}
.title2 {
  padding-left: 30px;
  padding-right: 30px;
  width: 24%;
  height: 100%;
  background: linear-gradient(135deg, #71a1de 0%, #5a82dd 46%, #516adc 100%);
  border-radius: 4px;
  position: relative;
}
.title3 {
  padding-left: 30px;
  padding-right: 30px;
  width: 24%;
  height: 100%;
  background: linear-gradient(135deg, #b97df9 0%, #ad6bf1 54%, #8c44e1 100%);
  border-radius: 4px;
  position: relative;
}
.title4 {
  padding-left: 30px;
  padding-right: 30px;
  width: 24%;
  height: 100%;
  background: linear-gradient(135deg, #6bcf84 0%, #6ec887 46%, #2aab4c 100%);
  border-radius: 4px;
  position: relative;
}

.body1 {
  width: 49.3333%;
  height: 100%;
  background: white;
  position: relative;
}
.body2 {
  width: 49.3333%;
  height: 100%;
  background: white;
  position: relative;
}
.square2-category {
  display: inline-block;
  min-width: 37px;
  padding: 2px 5px;
  height: 29px;
  border: 1px solid #3f6df8;
  border-right: none;
  color: #3f6df8;
  text-align: center;
  line-height: 22px;
  cursor: pointer;
}
.activeNH {
  background: #3f6df8;
  color: white;
}
</style>
<style>
.col-class1 {
  background: #ecf1fd !important;
}
thead tr {
  background: #ecf1fd !important;
}
thead tr th {
  background: #ecf1fd !important;
}
th {
  font-weight: 600 !important;
  color: #082254 !important;
}
.cell {
  color: #082254 !important;
}
</style>